export const environment = {
  env: 'org',
  abbreviation: 'lb',
  name: 'Lokale Bon',
  shortName: 'Lokale',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCBi6Dvcx73fZwU5HnMn2jUarL4viX05L8',
    authDomain: 'wemaron-vouchers-dev.firebaseapp.com',
    databaseURL: 'https://wemaron-vouchers-dev.firebaseio.com',
    projectId: 'wemaron-vouchers-dev',
    storageBucket: 'wemaron-vouchers-dev.appspot.com',
    messagingSenderId: '804844036454',
    appId: '1:804844036454:web:b365c1a59fba197dca28a7',
    measurementId: 'G-JQ5FFXX8MT',
  },
  functionsUrl: 'https://europe-west1-wemaron-vouchers-dev.cloudfunctions.net',
  iframeUrl: 'https://dev.frame.hallobon.nl/',
  prefix: 'lokale',
  prefixImgGroene:
    'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Flogos%2FGroeneBonLogo.png?alt=media&token=1fd16e67-db9c-4b21-9b69-ab2d556c6cb8',
  prefixImgLokale:
    'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Flogos%2FLokaleBonLogo.png?alt=media&token=406fab78-7625-46f5-a0ea-a59d37754fcd',
  prefixImgToegang:
    'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Flogos%2FToegangsBonLogo.png?alt=media&token=4ba400bd-4fe4-49fe-9ce3-c8eade4dcfda',
  loginMethod: {
    phone: false,
    email: true,
  },
  login: {
    image:
      'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Fbanners%2FLokaleBonBanner.png?alt=media&token=150e6eea-e8e4-4e9b-8ada-e46f849b0de6',
    title: {
      login: 'Log in als ondernemer',
      register: 'Begin met organiseren',
    },
    description: {
      login: 'log in op jouw ondernemersaccount',
      register:
        'registreer jouw organisatie om direct te beginnen met het inrichten van jouw Lokale Bon systeem',
    },
  },
  stepper: {
    environmentDB: 'lokaleBonOrg',
    maxSteps: 4,
    steps: {
      step1: {
        title: 'Welkom bij Lokale Bon',
        image:
          'https://www.researchgate.net/profile/Donald-Bailey-5/publication/224624453/figure/fig1/AS:393833717223438@1470908683517/Original-colour-bar-static-test-image-used-in-analogue-television-II-METHODOLOGY.png',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
      },
      step2: {
        title: 'Maak een evenement aan',
        image:
          'https://www.researchgate.net/profile/Donald-Bailey-5/publication/224624453/figure/fig1/AS:393833717223438@1470908683517/Original-colour-bar-static-test-image-used-in-analogue-television-II-METHODOLOGY.png',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
      },
      step3: {
        title: 'Nodig je vrienden uit',
        image:
          'https://www.researchgate.net/profile/Donald-Bailey-5/publication/224624453/figure/fig1/AS:393833717223438@1470908683517/Original-colour-bar-static-test-image-used-in-analogue-television-II-METHODOLOGY.png',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
      },
      step4: {
        title: 'Laat het evenement beginnen',
        image:
          'https://www.researchgate.net/profile/Donald-Bailey-5/publication/224624453/figure/fig1/AS:393833717223438@1470908683517/Original-colour-bar-static-test-image-used-in-analogue-television-II-METHODOLOGY.png',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
      },
    },
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  defaultRoute: {
    id: 'organisationmanagement',
    path: 'organisation-management',
  },
  routes: [
    'account',
    'multifactor',
    'login',
    'organisationmanagement',
    'promotionmaterials',
    'characteristics',
    'assignments',
    'users',
    'themes',
  ],
  chartScheme: [
    '#62C590',
    '#D7F0BC',
    '#066238',
    '#F5DC6A',
    '#FC9550',
    '#1A697F',
    '#E1DFD6',
  ],
  orgChartScheme: [
    '#FC9F5B', // Geclaimed
    '#1A697F', // Betaald
    '#62C590', // Beschikbaar
  ],
};
